import Api from '../base/index.js'

export default{
    async index(page=1, id, filters){
        return Api.get(`exam-subject/${id}`, {
            params : {
                page: page,
                standard_id: filters.standard_id,
                subject_group_id: filters.subject_group_id,
                exam_schedule_id: filters.exam_schedule_id,
            }
        } )
    },

    async show(id){
        return Api.get(`exam-subject-show/${id}`)
    },

    async all(id){
        return Api.get(`exam-subject-all/${id}`)
    },

    async create(data){
        return Api.post(`exam-subject`, data)
    },

    async update(id, data){
        return Api.put(`exam-subject/${id}`, data)
    },
    
    async delete(id){
        return Api.delete(`exam-subject/${id}`)
    }
}
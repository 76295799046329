import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`student-subject`, {
            params : {
                page: page,
                subject_id: id,
            }
        } )
    },

    // async all(){
    //     return Api.get(`student-subject-all`)
    // },

    // async create(data){
    //     return Api.post(`student-subject`, data)
    // },

    // async update(id, data){
    //     return Api.put(`student-subject/${id}`, data)
    // },
    
    // async delete(id){
    //     return Api.delete(`student-subject/${id}`)
    // }
}
import ExamAnswer from "../../../apis/exam/examAnswer.js";
export default {
  namespaced: true,

  state() {
    return {
      // resources: {
      //   data: [],
      //   current_page: 0,
      //   last_page: 1,
      //   total: 0,
      // },

      allResources: [],

      // keys: {
      //   resources: {
      //     localStorageName: "ExamAnswer",
      //   },
      // },

      // segment: "all",
    };
  },

  getters: {
    // resourcesKey(state) {
    //   return state.keys.resources;
    // },

    // resources(state) {
    //   return state.resources;
    // },

    allResources(state){
      return state.allResources;
    },

    // segment(state) {
    //   return state.segment;
    // },

    // nextResourcesPage(_, getters) {
    //   const resources = getters.resources;
    //   const current_page = resources.current_page;
    //   const last_page = resources.last_page;
    //   return last_page > current_page ? current_page + 1 : false;
    // },
  },

  mutations: {
    // resources(state, payLoad) {
    //   if (payLoad.refresh) {
    //     state.resources.data = payLoad.response.data;
    //   } else {
    //     state.resources.data.push(...payLoad.response.data);
    //   }
    //   state.resources.current_page = payLoad.response.current_page;
    //   state.resources.last_page = payLoad.response.last_page;
    //   state.resources.total = payLoad.response.total;
    //   localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    // },

    // segment(state, payLoad) {
    //   state.segment = payLoad;
    // },

    allResources(state, payLoad){
      state.allResources = payLoad;
    }
  },

  actions: {
    // async resources(context, payLoad) {
    //   const refresh = payLoad.refresh ? true : false;
    //   const key = context.getters.resourcesKey;
    //   const segment = context.getters.segment;
    //   //   const filters = context.getters.filters;
    //   const resourceId = payLoad.resourceId;
    //   const inLocalStorage = refresh
    //     ? null
    //     : await JSON.parse(localStorage.getItem(key.localStorageName));

    //   if (inLocalStorage) {
    //     context.state.resources = inLocalStorage;
    //   }

    //   const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

    //   if (nextResourcesPage) {
    //     //  const response = await ImportedApi.index(page)
    //     const response = await ExamAnswer.index(
    //       nextResourcesPage,
    //       resourceId,
    //       segment
    //     );

    //     const dispatch = {
    //       response: response.data,
    //       refresh: refresh,
    //       key: key.localStorageName,
    //     };
    //     context.commit("resources", dispatch);
    //   }
    // },

    async createResource(_, payLoad) {
      // const response = await ImportedApi.create(resource_id)
      await ExamAnswer.create(payLoad);
      // context.dispatch("resources", { refresh: true, resourceId: payLoad.exam_id });
    },

    async updateResource(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad;
      await ExamAnswer.update(payLoad.id, data);
      context.dispatch("allResources", payLoad.exam_subject_id);
    },

    async evaluate(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad;
      await ExamAnswer.evaluate(payLoad.exam_answer_id, data);
      context.dispatch("allResources", payLoad);
    },

    // async deleteResource(context, payLoad) {
    //   // const response = await ImportedApi.delete(resource_id)
    //   await ExamAnswer.delete(payLoad.id);
    //   context.dispatch("resources", { refresh: true, resourceId: payLoad.exam_id });
    // },

    async allResources(context, payLoad){
      // All resources without pagination
      const exam_subject_id = payLoad.exam_subject_id
      const allResources = await ExamAnswer.all(exam_subject_id, payLoad);
      context.commit("allResources", allResources.data)
    },

    // async segment(context, payLoad) {
    //   await context.commit("segment", payLoad.segment);
    //   context.dispatch("resources", { refresh: true, resourceId: payLoad.exam_id });
    // },
  },
};

import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`section-standard?page=${page}`)
    },

    async all(){
        return Api.get(`section-standard-all`)
    },

    async show(class_id, page=1){
        return Api.get(`section-standard/${class_id}?page=${page}`)
    },

    async create(data){
        return Api.post(`section-standard`, data)
    },

    async update(id, data){
        return Api.put(`section-standard/${id}`, data)
    },

    async delete(id){
        return Api.delete(`section-standard/${id}`)
    }
}
import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`exam-standard/${id}`, {
            params : {
                page: page,
            }
        } )
    },

    // async all(id){
    //     return Api.get(`exam-standard-all/${id}`)
    // },

    // async create(data){
    //     return Api.post(`exam-standard`, data)
    // },

    // async update(id, data){
    //     return Api.put(`exam-standard/${id}`, data)
    // },
    
    // async delete(id){
    //     return Api.delete(`exam-standard/${id}`)
    // }
}
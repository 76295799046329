import Api from '../base/index.js'

export default{
    // async index(page=1, id, filters){
    //     return Api.get(`student-users-for-exam-subject/${id}`, {
    //         params : {
    //             page: page,
    //             standard_id: filters.standard_id,
    //             section_id: filters.section_id,
    //         }
    //     } )
    // },

    // async show(id){
    //     return Api.get(`stuent-users-for-exam-subject-show/${id}`)
    // },

    async all(id){
        return Api.get(`student-users-for-exam-subject-all/${id}`)
    },

    // async create(data){
    //     return Api.post(`stuent-users-for-exam-subject`, data)
    // },

    // async update(id, data){
    //     return Api.put(`student-users-for-exam-subject/${id}`, data)
    // },
    
    // async delete(id){
    //     return Api.delete(`exam-subject/${id}`)
    // }
}
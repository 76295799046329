import Api from "../base/index.js";

export default {
  async index(page = 1, segment) {
    return Api.get(`exam`, {
      params: {
        page,
        segment,
      },
    });
  },

  // async all(){
  //     return Api.get(`fee-all`)
  // },

  async show(id) {
    return Api.get(`exam/${id}`);
  },

  async create(data) {
    return Api.post(`exam`, data);
  },

  async update(id, data) {
    return Api.put(`exam/${id}`, data);
  },

  async delete(id) {
    return Api.delete(`exam/${id}`);
  },
};

import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";
import attributeModule from "./modules/attribute/attribute.js";
import classModule from "./modules/Setup/class.js";

import sessionModule from "./modules/Setup/session.js";
import subjectModule from "./modules/academic/subject.js";
import subjectGroupModule from "./modules/academic/subjectGroup.js";
import subjectStudentModule from "./modules/academic/subjectStudent.js";
import chapterModule from "./modules/academic/chapter.js";
import chapterProgressionModule from "./modules/academic/chapterProgression.js";

import attendanceModule from "./modules/attendance/attendance.js";
import attendanceClassModule from "./modules/attendance/attendanceClass.js";
import attendanceClassroomModule from "./modules/attendance/attendanceClassroom.js";

import teacherSubjectBySectionStandardModule from "./modules/academic/teacherSubjectBySectionStandard.js"

import homeworkModule from "./modules/homework/homework.js";

import appealModule from "./modules/appeal/appeal.js";

import eventModule from "./modules/calendar/event.js"

import notificationModule from "./modules/notification/notification.js";

import exam from "./modules/exam/exam.js";
import examSchedule from "./modules/exam/examSchedule.js";
import examSectionStandard from "./modules/exam/examSectionStandard.js";
import examSubject from "./modules/exam/examSubject.js";
import examUser from "./modules/exam/examUser.js";
import examQuestion from "./modules/exam/examQuestion.js";
import studentUsersForExamSubject from "./modules/exam/StudentUsersForExamSubject.js";
import examAnswer from "./modules/exam/examAnswer.js";
import examSubjectScore from "./modules/exam/examSubjectScore.js";
import examStandard from "./modules/exam/examStandard.js";

import tinyMce from "./modules/tinyMce/tinyMce.js";

const store = createStore({
  modules: {
    auth: authModule,
    attribute: attributeModule,
    class: classModule,

    session: sessionModule,
    subject:subjectModule,
    subjectGroup: subjectGroupModule,
    subjectStudent:subjectStudentModule,
    chapter:chapterModule,
    chapterProgression: chapterProgressionModule,

    attendance: attendanceModule,
    attendanceClass: attendanceClassModule,
    attendanceClassroom: attendanceClassroomModule,

    homework: homeworkModule,
    appeal: appealModule,
    teacherSubjectBySectionStandard: teacherSubjectBySectionStandardModule,

    exam,
    examSchedule,
    examSectionStandard,
    examSubject,
    examQuestion,
    studentUsersForExamSubject,
    examAnswer,
    examSubjectScore,
    examStandard,
    examUser,

    tinyMce,

    event:eventModule,

    notification: notificationModule,
  },

  state() {
    return {
      reload: null,
    };
  },

  getters: {
    reload(state) {
      return state.reload;
    },
  },

  mutations: {
    clearReload(state) {
      state.reload = null;
    },

    setReload(state, payLoad) {
      state.reload = payLoad;
    },
  },

  actions: {
    clearReload(context) {
      context.commit("clearReload");
    },

    setReload(context, payLoad) {
      context.commit("setReload", payLoad);
    },
  },
});

export default store;

import Api from '../base/index.js'

export default{
    async index(page=1, id, filters){
        return Api.get(`exam-user/${id}`, {
            params : {
                page: page,
                standard_id: filters.standard_id,
                section_id: filters.section_id,
            }
        } )
    },

    async show(id){
        return Api.get(`exam-user-show/${id}`)
    },

    // async all(id){
    //     return Api.get(`exam-subject-all/${id}`)
    // },

    // async create(data){
    //     return Api.post(`exam-subject`, data)
    // },

    async update(id, data){
        return Api.put(`exam-user/${id}`, data)
    },
    
    // async delete(id){
    //     return Api.delete(`exam-subject/${id}`)
    // }
}
<template>
  <ion-menu content-id="main-content" type="overlay" side="start">
    <ion-content>
      <ion-list id="inbox-list">
        <ion-menu-toggle auto-hide="false">
          <ion-item
            lines="none"
            class="hydrated link"
            router-direction="root"
            :router-link="{ name: 'profile' }"
          >
            <ion-avatar slot="start">
              <ion-img :src="profile_picture"></ion-img>
            </ion-avatar>
            <ion-label>
              <h3>{{ user.user_detail.name }}</h3>
              <p>Teacher</p>
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-note>{{ capacitorConfig.schoolName }}</ion-note>
        <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
          <ion-item
            router-direction="root"
            :router-link="{ name: p.routeName }"
            lines="none"
            detail="false"
            class="hydrated link"
            :class="{ selected: p.routeName === currentRouteName }"
          >
            <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-item-divider lines="none" class="link"> </ion-item-divider>
        <ion-menu-toggle auto-hide="true">
          <ion-item
            @click="logout()"
            lines="none"
            detail="false"
            class="hydrated link"
          >
            <ion-icon
              color="danger"
              slot="start"
              :ios="logOutOutline"
              :md="logOutSharp"
            ></ion-icon>
            <ion-label>Logout</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <!-- <ion-menu-toggle>
        <ion-list id="labels-list">
          <ion-item lines="none" class="link" @click="logout()">
            <ion-icon
              color="danger"
              slot="start"
              :ios="logOutOutline"
              :md="logOutSharp"
            ></ion-icon>
            <ion-label> Logout </ion-label>
          </ion-item>
        </ion-list>
      </ion-menu-toggle> -->
    </ion-content>
  </ion-menu>
</template>
<script>
import {
  IonContent,
  IonIcon,
  IonItem,
  IonImg,
  IonAvatar,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/vue";

import {
  logOutOutline,
  logOutSharp,
  peopleCircleOutline,
  peopleCircleSharp,
  barChartOutline,
  barChartSharp,
  clipboardOutline,
  clipboardSharp,
  calendarOutline,
  calendarSharp,
  bookOutline,
  bookSharp,
  documentTextOutline,
  documentTextSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  layersOutline,
  layersSharp,
  schoolOutline,
  schoolSharp,
} from "ionicons/icons";

import capacitorConfig from "../../../capacitor.config.json";
import BaseUrl from "../../apis/base_url";

export default {
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonImg,
    IonAvatar,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
  },

  data() {
    return {
      capacitorConfig,
      logo: "assets/img/logo.png",
      appPages: [
        {
          title: "Dashboard",
          routeName: "dashboard",
          iosIcon: barChartOutline,
          mdIcon: barChartSharp,
        },

        {
          title: "Attendance",
          routeName: "attendance",
          iosIcon: clipboardOutline,
          mdIcon: clipboardSharp,
        },

        {
          title: "Homeworks",
          routeName: "homework",
          iosIcon: documentTextOutline,
          mdIcon: documentTextSharp,
        },

        {
          title: "Academics",
          routeName: "academic",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },

        {
          title: "Calendar & Events",
          routeName: "calendar",
          iosIcon: calendarOutline,
          mdIcon: calendarSharp,
        },

        {
          title: "Appeals",
          routeName: "appeal",
          iosIcon: layersOutline,
          mdIcon: layersSharp,
        },

        {
          title: "Notifications",
          routeName: "notification.show",
          iosIcon: paperPlaneOutline,
          mdIcon: paperPlaneSharp,
        },

        {
          title: "Exams",
          routeName: "exam.list",
          iosIcon: schoolOutline,
          mdIcon: schoolSharp,
        },
      ],
      logOutOutline,
      logOutSharp,
      peopleCircleOutline,
      peopleCircleSharp,
      layersOutline,
      layersSharp,
    };
  },

  computed: {
    profile_picture() {
      return this.user.profile_picture
        ? BaseUrl + "storage/" + this.user.profile_picture.url
        : this.logo;
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {
    async logout() {
      try {
        await this.$store.dispatch("auth/logout");
        this.$router.replace({ name: "login" });
      } catch (error) {
        this.$store.dispatch("auth/forceLogout");
        this.$router.replace({ name: "login" });
      }
    },
  },
};
</script>

<style scoped>
.split-pane-visible > .split-pane-side {
  /* min-width: 150px!important; */
  max-width: 300px !important;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

/* ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
} */

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>

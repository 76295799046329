import Exam from "../../../apis/exam/exam.js";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      specificResource: null,

      keys: {
        resources: {
          localStorageName: "Exams",
        },
      },

      //   filters: {
      //     SubjectTeacher_group_id: null,
      //     standard_id: null,
      //   },
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },

    resources(state) {
      return state.resources;
    },

    // filters(state) {
    //   return state.filters;
    // },
    segment(state) {
      return state.segment;
    },

    specificResource(state){
      return state.specificResource;
    },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    // filters(state, payLoad) {
    //   state.filters = payLoad;
    // },

    segment(state, payLoad) {
      state.segment = payLoad;
    },

    specificResource(state, payLoad){
      state.specificResource = payLoad;
    }
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      //   const filters = context.getters.filters;
      const segment = context.getters.segment;
      // const resourceId = payLoad.resourceId;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

      if (nextResourcesPage) {
        //  const response = await ImportedApi.index(page)
        const response = await Exam.index(
          nextResourcesPage,
          segment,
          // resourceId,
        );

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };
        context.commit("resources", dispatch);
      }
    },

    async createResource(context, payLoad) {
      // const response = await ImportedApi.create(resource_id)
      await Exam.create(payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async updateResource(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad;
      await Exam.update(payLoad.id, data);
      context.dispatch("resources", {
        refresh: true,
        resourceId: payLoad.subject_id,
      });
    },

    async deleteResource(context, payLoad) {
      // const response = await ImportedApi.delete(resource_id)
      await Exam.delete(payLoad.resourceId);
      context.dispatch("resources", { refresh: true });
    },

    // async filters(context, payLoad) {
    //   await context.commit("filters", payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    async segment(context, payLoad) {
      await context.commit("segment", payLoad.segment);
      context.dispatch("resources", { refresh: true, resourceId: payLoad.exam_id });
    },

    async specificResource(context, payLoad) {
      // Specific resources without pagination
      const specificResource = await Exam.show(payLoad);
      context.commit("specificResource", specificResource.data)
    }
  },
};

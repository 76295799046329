import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`homework?page=${page}`)
    },

    // async all(){
    //     return Api.get(`fee-all`)
    // },

    // async show(id){
    //     return Api.get(`fee/${id}`)
    // },

    async create(data){
        return Api.post(`homework`, data)
    },

    async update(id, data){
        return Api.put(`homework/${id}`, data)
    },

    async delete(id){
        return Api.delete(`homework/${id}`)
    }
}
import Api from '../base/index.js'

export default{
    // async index(page=1){
    //     return Api.get(`attendance?page=${page}`)
    // },

    // async show(id, page=1){
    //     return Api.get(`attendance/${id}?page=${page}`)
    // },

    async attendanceForAssignedClasses(user_id){
        return Api.get(`attendance-for-assigned-class/${user_id}`)
    },

    async create(data){
        return Api.post(`attendance`, data)
    },

    async update(id, data){
        return Api.put(`attendance/${id}`, data)
    },

    // async delete(id){
    //     return Api.delete(`attendance/${id}`)
    // }
}
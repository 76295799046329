import Attribute from "../../../apis/atribute/attribute.js";
export default {
  namespaced: true,

  state() {
    return {
      genders: [],
      languages: [],
      religions: [],
      castes: [],
      bloodGroups: [],
      banks: [],
      instruments: [],
      notificationTypes: [],
      appealTypes: [],
      examTypes: [],
      examQuestionTypes: [],
      examAnswerState: [],
    };
  },

  getters: {
    genders(state){
      return state.genders;
    },
    languages(state){
      return state.languages;
    },
    religions(state){
      return state.religions;
    },
    castes(state){
      return state.castes;
    },
    bloodGroups(state){
      return state.bloodGroups;
    },
    banks(state){
      return state.banks;
    },
    instruments(state){
      return state.instruments;
    },
    notificationTypes(state){
      return state.notificationTypes;
    },
    appealTypes(state){
      return state.appealTypes;
    },
    examTypes(state){
      return state.examTypes;
    },
    examAnswerState(state){
      return state.examAnswerState;
    },
    examQuestionTypes(state){
      return state.examQuestionTypes;
    },
  },

  mutations: {
    genders(state, payLoad){
      state.genders = payLoad;
    },
    languages(state, payLoad){
      state.languages = payLoad;
    },
    religions(state, payLoad){
      state.religions = payLoad;
    },
    castes(state, payLoad){
      state.castes = payLoad;
    },
    bloodGroups(state, payLoad){
      state.bloodGroups = payLoad;
    },
    banks(state, payLoad){
      state.banks = payLoad;
    },
    instruments(state, payLoad){
      state.instruments = payLoad;
    },
    notificationTypes(state, payLoad){
      state.notificationTypes = payLoad;
    },
    appealTypes(state, payLoad){
      state.appealTypes = payLoad;
    },
    examTypes(state, payLoad){
      state.examTypes = payLoad;
    },
    examQuestionTypes(state, payLoad){
      state.examQuestionTypes = payLoad;
    },
    examAnswerState(state, payLoad){
      state.examAnswerState = payLoad;
    },
  },

  actions: {
    async genders(context){
      // All resources without pagination
      const genders = await Attribute.gender();
      context.commit("genders", genders.data)
    },
    async languages(context){
      // All resources without pagination
      const languages = await Attribute.language();
      context.commit("languages", languages.data)
    },
    async religions(context){
      // All resources without pagination
      const religions = await Attribute.religion();
      context.commit("religions", religions.data)
    },
    async castes(context){
      // All resources without pagination
      const castes = await Attribute.caste();
      context.commit("castes", castes.data)
    },
    async bloodGroups(context){
      // All resources without pagination
      const bloodGroups = await Attribute.bloodGroup();
      context.commit("bloodGroups", bloodGroups.data)
    },
    async banks(context){
      // All resources without pagination
      const banks = await Attribute.bank();
      context.commit("banks", banks.data)
    },
    async instruments(context){
      // All resources without pagination
      const instruments = await Attribute.instrument();
      context.commit("instruments", instruments.data)
    },
    async notificationTypes(context){
      // All resources without pagination
      const notificationTypes = await Attribute.notificationTypes();
      context.commit("notificationTypes", notificationTypes.data)
    },
    async appealTypes(context){
      // All resources without pagination
      const appealTypes = await Attribute.appealTypes();
      context.commit("appealTypes", appealTypes.data)
    },
    async examTypes(context){
      // All resources without pagination
      const examTypes = await Attribute.examTypes();
      context.commit("examTypes", examTypes.data)
    },
    async examQuestionTypes(context){
      // All resources without pagination
      const examQuestionTypes = await Attribute.examQuestionTypes();
      context.commit("examQuestionTypes", examQuestionTypes.data)
    },
    async examAnswerState(context){
      // All resources without pagination
      const examAnswerState = await Attribute.examAnswerState();
      context.commit("examAnswerState", examAnswerState.data)
    },
  },
};

import Api from '../base/index.js'

export default{
    async gender(){
        return Api.get(`gender`)
    },

    async language(){
        return Api.get(`language`)
    },

    async religion(){
        return Api.get(`religion`)
    },

    async caste(){
        return Api.get(`caste`)
    },

    async bloodGroup(){
        return Api.get(`blood-group`)
    },
    
    async bank(){
        return Api.get(`bank`)
    },
    async instrument(){
        return Api.get(`instrument`)
    },

    async notificationTypes(){
        return Api.get(`notification-type`)
    },
    async appealTypes(){
        return Api.get(`appeal-type`)
    },
    async examTypes(){
        return Api.get(`exam-type`)
    },
    async examQuestionTypes(){
        return Api.get(`exam-question-type`)
    },
    async examAnswerState(){
        return Api.get('exam-answer-state')
    },
}
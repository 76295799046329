 
import Pwa from "./Pwa.js";
import Csrf from "./Csrf";

export default {
  async register(form) {
    await Csrf.getCookie();

    return Pwa.post("pwa/register", form);
  },

  async login(form) {
    await Csrf.getCookie();

    return Pwa.post("pwa/teacher-login", form);
  },

  async logout() {
    await Csrf.getCookie();

    return Pwa.post("pwa/logout");
  }
};
import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`exam-section-standard/${id}`, {
            params : {
                page: page,
            }
        } )
    },

    // async all(id){
    //     return Api.get(`exam-section-standard-all/${id}`)
    // },

    async create(data){
        return Api.post(`exam-section-standard`, data)
    },

    // async update(id, data){
    //     return Api.put(`exam-section-standard/${id}`, data)
    // },
    
    async delete(id){
        return Api.delete(`exam-section-standard/${id}`)
    }
}
import Api from '../base/index.js'

export default{
    async index(id, page=1, date){
        return Api.get(`attendance/${id}?page=${page}&attendance_date=${date}`)
    },

    // async create(data){
    //     return Api.post(`student`, data)
    // },

    // async updateStudent(id, data){
    //     return Api.put(`student/${id}`, data)
    // },

    // async deleteStudent(id){
    //     return Api.delete(`student/${id}`)
    // }
}